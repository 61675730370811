<template>
  <div>
    <h1 class="main-title">{{ activity_name }}</h1>

    <a-card>
      <a-form-model
          class="search-form"
          ref="search_form"
          :model="search_form"
          :label-col="{span: 5}"
          :wrapper-col="{span: 19}"
      >
        <a-row :gutter="60">
          <a-col :span="8">
            <a-form-model-item label="经销商名称">
              <a-input
                  placeholder="请输入名称"
                  v-model="search_form.dealer_name"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="经销商ID">
              <a-input
                  placeholder="请输入ID"
                  v-model="search_form.dealer_id"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="分组筛选">
              <a-select
                  v-model="search_form.groupId"
                  placeholder="请选择"
              >
                <a-select-option key=""> 全部</a-select-option>
                <a-select-option
                    v-for="item in group_list"
                    :key="item.id"
                >
                  {{ item.groupName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="媒体帐号">
              <a-input
                  placeholder="请输入"
                  v-model="search_form.code"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="媒体帐号ID">
              <a-input
                  placeholder="请输入"
                  v-model="search_form.author_id"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="创作者平台状态">
              <a-select v-model="search_form.p1_oauth_status">
                <a-select-option v-for="item in creatorPlatformList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="互动数据状态">
              <a-select v-model="search_form.p2_oauth_status">
                <a-select-option v-for="item in openPlatformList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="微信状态">
              <a-select v-model="search_form.weixin_status">
                <a-select-option v-for="item in WeChatStatusList" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="跟进人">
              <a-select
                  v-model="search_form.follow_id"
                  show-search
                  allow-clear
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @search="handleDealerSearch"
                  :default-active-first-option="false"
                  :not-found-content="isFollowLoading ? undefined : null"
              >
                <a-select-option v-for="item in follow_id_list" :key="item.id" :value="item.id">
                  {{ item.employee_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :wrapper-col="{span: 19, offset: 5}">
              <a-space>
                <a-button
                    type="primary"
                    icon="search"
                    @click="bindSearch"
                >
                  搜索
                </a-button>
                <a-button
                    icon="redo"
                    @click="resetSearch"
                >
                  重置
                </a-button>
              </a-space>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col class="flex-end">
            <a-button
                class="mr-10"
                type="primary"
                @click="showaddAccountVisible"
            >
              添加媒体帐号
            </a-button>

          </a-col>
        </a-row>
      </a-form-model>

      <div class="danger_btn">
        <a-button
            type="link"
            style="padding-right: 20px;"
            @click="pageJump"
        >
          KPI设置
        </a-button>
        <a-button
            type="danger"
            icon="plus"
            @click="showDrawer"
        >
          导入经销商
        </a-button>
      </div>

      <div class="operation-box">
        <div>已选择{{ selectedRowKeys.length }}位经销商</div>
        <div class="btn">
          <a-button
              type="link"
              :loading="is_submit"
              :disabled="is_submit"
              @click="addGroup"
          >
            添加跟进人
          </a-button>
        </div>
        <div>
          <span>经销商数量：</span> <span class="num">{{ dealer_count || 0 }}</span>
          <span>媒体帐号数量：</span> <span class="num">{{ author_count || 0 }}</span>
          <span>创作者平台授权异常：</span> <span class="num red">{{ p1_exception_count || 0 }}</span>
          <span>互动数据授权异常：</span> <span class="num red">{{ p2_exception_count || 0 }}</span>
          <span>微信授权异常：</span> <span class="num red">{{ unbind_weixin_count || 0 }}</span>
        </div>
      </div>

      <div class="data-table">
        <a-table
            :columns="columns"
            :data-source="data_list"
            :pagination="false"
            :scroll="{ x: 1500,y: 'calc(100vh - 400px)'}"
            :rowKey="(record) => record.id"
            :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onChangeRowSelection,
        }"
            :loading="loading"
        >
          <div slot="dealer_info" slot-scope="text, record">
            <div class="dealer_info"><span>名称：</span><span>{{ record.dealer_name || "-" }}</span></div>
            <div class="dealer_info"><span>ID：</span><span>{{ record.dealer_id || "-" }}</span></div>
            <div class="dealer_info"><span>编码：</span><span>{{
                record.dealer_number ? record.dealer_number : record.store_code ? record.store_code : "-"
              }}</span></div>
            <div class="dealer_info"><span>分组：</span><span>{{ record.group_name || "-" }}</span></div>
            <div class="dealer_info"><span>跟进人：</span><span>{{ record.follow_name || "-" }}</span></div>
          </div>
          <div slot="custom_info" slot-scope="text, record" v-if="record.remarksObj">
            <div class="dealer_info" v-for="(value,key) in record.remarksObj" :key="key">
              <span>{{ key }}：</span><span>{{ value || "-" }}</span>
            </div>
            <div class="dealer_info"></div>
          </div>
          <div slot="account" slot-scope="text, record">
            <div class="account_box">
              <span>头像：</span>
              <div class="img_box" v-if="record.avatar">
                <img :src="record.avatar" alt="头像">
              </div>
              <a-avatar :size="48" icon="user" style="margin-right: 10px" v-else/>
            </div>
            <div class="account_box">
              <span>昵称：</span> <span class="name">{{ record.nickname || "-" }}</span>
            </div>
            <div class="account_box">
              <span>ID：</span><span>{{ record.code || "-" }}</span>
            </div>
            <div class="account_box">
              <span>平台号：</span><span>{{ record.codes || "-" }}</span>
            </div>
            <div class="account_box">
              <span>平台：</span><span>{{ record.platform || "-" }}</span>
            </div>
          </div>
          <div slot="status" slot-scope="text, record">
            <div class="account_box">
              <span>创造者平台：</span>
              <div v-if="record.platform && record.platform !== 3">
                <a-tag color="green" v-if="record.p1_oauth_status == 1">已授权</a-tag>
                <a-tag color="red" v-else-if="record.p1_oauth_status == 2">授权失效</a-tag>
                <a-tag color="orange" v-else>未授权</a-tag>
              </div>
              <div v-else>--</div>
            </div>
            <div class="account_box">
              <span>互动数据：</span>
              <div v-if="record.platform && record.platform !== 3">
                <a-tag color="green" v-if="record.p2_oauth_status == 1">已授权</a-tag>
                <a-tag color="red" v-else-if="record.p2_oauth_status == 2">授权过期</a-tag>
                <a-tag color="purple" v-else-if="record.p2_oauth_status == 3">部分接口未授权，需要重新授权，补充授权项
                </a-tag>
                <a-tag color="purple" v-else-if="record.p2_oauth_status == 4">正在检查用户一致性</a-tag>
                <a-tag color="purple" v-else-if="record.p2_oauth_status == 5">帐号一致性检查过程报错</a-tag>
                <a-tag color="purple" v-else-if="record.p2_oauth_status == 6">需要授权的帐号与扫码帐号不一致</a-tag>
                <a-tag color="orange" v-else>未授权</a-tag>
              </div>
              <div v-else>--</div>
            </div>
            <div class="account_box">
              <span>微信：</span>
              <div v-if="record.platform && record.platform !== 3">
                <a-tag color="green" v-if="record.weixin_status == 1">已授权</a-tag>
                <a-tag color="red" v-else-if="record.weixin_status == 2">授权失败</a-tag>
                <a-tag color="orange" v-else>未授权</a-tag>
              </div>
              <div v-else>--</div>
            </div>
          </div>
          <div slot="operation" slot-scope="text, record">
            <a class="del" @click="clickRowKey(1, record)">删除</a>
            <a-divider type="vertical" v-if="record.remarksObj"/>
            <a @click="clickRowKey(2, record)" v-if="record.remarksObj">编辑自定义信息</a>
          </div>
        </a-table>
        <!-- 分页配置 -->
        <base-pagination
            :options="['5', '10','50']"
            :currentPage="current"
            :pageSize="page_size"
            :total="total"
            @onChange="onChange"
            @onShowSizeChange="onChange"
        />
      </div>
    </a-card>

    <!-- 导入经销商 -->
    <a-drawer
        title="导入经销商"
        placement="right"
        :width="1000"
        :maskClosable="false"
        :visible="drawerVisible"
        @close="handleCancel"
    >
      <h3 class="title" style="margin-bottom: 20px;">{{ activity_name }}</h3>
      <a-radio-group
          v-model="open_type"
          style="margin-bottom: 20px"
          @change="changeRadio"
      >
        <a-radio :value="1"> 表格导入</a-radio>
        <a-radio :value="2"> 选择分组</a-radio>
      </a-radio-group>
      <div v-if="open_type == 1">
        <!-- <div style="display: flex;align-items: center;margin-bottom: 20px">
          <span>分组名称<i class="i_icon">*</i>：</span>
          <a-input v-model="group_name" placeholder="请输入分组名称" style="width: 30%;margin-left: 12px"/>
        </div> -->
        <div class="upload_box">
          <a-upload-dragger
              name="file"
              accept=".XLSX, .XLS, .xlsx, .xls"
              :file-list="file_list"
              :custom-request="customRequest"
              @change="handleUploadChange"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" style="font-size: 60px;color:#00A0E9;"/>
            </p>
            <p class="ant-upload-text">
              点击或将文件拖拽到这里上传
            </p>
            <p class="ant-upload-hint">
              支持扩展名：.XLSX
            </p>
          </a-upload-dragger>
        </div>

        <!-- 自定义表头-->
        <div class="header_list">
          <p class="header_list_title">导入经销商模板表头</p>
          <template v-for="(tag, index) in header_list">
            <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
              <a-tag :key="tag" :closable="index !== 0" @close="() => handleClose(tag)">
                {{ `${ tag.slice(0, 20) }...` }}
              </a-tag>
            </a-tooltip>
            <a-tag v-else :key="tag" :closable="index > 4" @close="() => handleClose(tag)">
              {{ tag }}
            </a-tag>
          </template>
          <div v-if="inputVisible" style="display: inline-block">
            <a-input
                ref="input"
                type="text"
                size="small"
                placeholder="请输入自定义表头"
                :style="{ width: '150px'}"
                :value="inputValue"
                @change="handleInputChange"
                @keyup.enter="handleInputConfirm"
            />
            <a-button type="link" @click="handleInputConfirm">确定</a-button>
            <a-button type="link" @click="handleInputClose" style="margin-left: -20px">撤销</a-button>
          </div>
          <a-tag v-else style="background: #fff; borderStyle: dashed" @click="showInput">
            <a-icon type="plus"/>
          </a-tag>
        </div>


        <!--校验成功-->
        <div class="success_box" v-if="file_status == 1">
          <div class="icon—box">
            <a-icon type="check-circle" theme="filled" style="font-size: 60px;color:#72cc07;"/>
            <div>
              <p>校验成功</p>
              <span>
                请检查统计数据
              </span>
            </div>
          </div>
          <div class="success_tabs">
            <a-table
                :scroll="{ y: 300 }"
                bordered
                :columns="upload_success_columns"
                :data-source="upload_success_data_list"
                :pagination="false"
                :rowKey="(record, index) => index"
            >
            </a-table>
          </div>
        </div>

        <!--校验失败-->
        <div class="success_box err_box" v-if="file_status == 0">
          <div class="icon—box">
            <a-icon type="close-circle" theme="filled" style="font-size: 60px;color:red;"/>
            <div>
              <p>校验失败</p>
              <span>请检查帐号异常数据或联系数据管理员。</span>
              <a href="#" @click="downloadDealerError">下载帐号异常数据</a>
            </div>
          </div>
          <div class="success_tabs" v-show="upload_error_data_list.length >0">
            <a-table
                :scroll="{ y: 300 }"
                bordered
                :columns="upload_error_columns"
                :data-source="upload_error_data_list"
                :pagination="false"
                :rowKey="(record, index) => index"
            >
              <div slot="dealerInfo" slot-scope="text, record">
                <div>
                  <p>名称：{{ record.dealer_name }} </p>
                  <p>ID：{{ record.dealer_id }} </p>
                </div>
              </div>
              <div slot="activityInfo" slot-scope="text, record">
                <div>
                  <p>媒体帐号ID：{{ record.author_id }} </p>
                  <p>平台：{{ record.platform }}</p>
                </div>
              </div>
              <div slot="errorMsg" slot-scope="text, record">
                <p style="color:red;">{{ record.validate_message }}</p>
              </div>
            </a-table>
          </div>
        </div>

        <div class="flex-end model_btn" style="padding-top: 10%">
          <a href="#" @click="exportExcelModel">下载导入经销商模板</a>
          <a-button
              type="primary"
              :loading="is_upload"
              :disabled="is_upload || !keysSuccess"
              @click="handleSave"
          >
            保存
          </a-button>
        </div>
      </div>
      <div v-if="open_type == 2">
        <templateList
            :activityId="String(activity_id)"
            :principalId="principal_id"
            @handleCancel="handleCancelTemplate"
        ></templateList>
      </div>
    </a-drawer>

    <!-- 添加分组 -->
    <a-modal v-model="addGroupVisible" width="50%" title="添加到分组" @ok="handleOkAddGroup">
      <div class="add_input">
        <a-input
            placeholder="请输入分组名称"
            v-model="group_name"
            style="width: 300px;margin-right: 40px"
        />
        <a-button
            :type="'primary'"
            :title="'添加'"
            @onClickBtn="addGroupName"
            class="mr-10"
        />
      </div>
      <p class="group_name">当前选择的分组： <span>{{ groupName }}</span></p>
      <div>
        <a-table
            :columns="group_columns"
            :data-source="group_list"
            :pagination="false"
            :rowKey="(record, index) => index"
        >
          <div slot="operation" slot-scope="text, record">
            <a @click="handleChexkout(record)">选择</a>
            <a class="del" @click="handleDelete(record.id)">删除</a>
          </div>
        </a-table>
      </div>
    </a-modal>

    <!-- 添加媒体帐号 -->
    <a-modal v-model="addAccountVisible" width="800px" title="添加媒体帐号" @ok="handleOkSaveAccount">
      <div class="add_input">
        <a-form-model
            :layout="'inline'"
            ref="search_form"
            :model="search_account_form"
        >
          <a-form-model-item
              label="平台"
          >
            <a-select style="width:240px" v-model="search_account_form.platform">
              <a-select-option value="抖音">
                抖音
              </a-select-option>
              <a-select-option value="快手">
                快手
              </a-select-option>
              <a-select-option value="懂车帝">
                懂车帝
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
              label="昵称搜索"
          >
            <a-input
                style="width:260px"
                placeholder="请输入昵称"
                v-model="search_account_form.nickname"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button
                class="mr-10"
                type="primary"
                @click="searchAccount"
            >
              搜索
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <p class="group_name">当前选择： <span>{{ accountName }}</span></p>
      <div>
        <a-table
            :columns="account_columns"
            :data-source="account_list"
            :pagination="false"
            :rowKey="(record, index) => index"
            :scroll="{y: 300 }"
        >
          <div slot="account" slot-scope="text, record">
            <div class="avatar_box">
              <div class="img_box" v-if="record.avatar">
                <img :src="record.avatar" alt="头像">
              </div>
              <a-avatar :size="32" icon="user" style="margin-right: 10px" v-else/>
            </div>
          </div>
          <div slot="dealer_name" slot-scope="text, record">
            <p>{{ record.dealer_name || "-" }}</p>
          </div>
          <div slot="operation" slot-scope="text, record">
            <a @click="handleAccountChexkout(record)">选择</a>
          </div>
        </a-table>

        <!-- 分页配置 -->
        <base-pagination
            :options="['5', '10','50']"
            :currentPage="account_current"
            :pageSize="account_page_size"
            :total="account_total"
            @onChange="onChangeAccount"
            @onShowSizeChange="onChangeAccount"
        >
        </base-pagination>
      </div>
    </a-modal>


    <!-- 编辑自定义信息 -->
    <a-modal v-model="customVisible" width="520px" title="编辑自定义信息" @ok="handleOkCustomVisible">
      <a-form-model
          :layout='"horizontal"'
          ref="custom_form"
          v-bind="formItemLayout"
          :model="Obj"
      >
        <div v-for="(value, key) in Obj" :key="key">
          <a-form-model-item
              :label="key"
          >
            <a-input
                v-model="Obj[key]"
            />
          </a-form-model-item>
        </div>
        <!--        <a-form-model-item
                  label='学员名称'
                >
                  <a-input
                    v-model='custom_form.name'
                  />
                </a-form-model-item>
                <a-form-model-item
                  label='学员手机'
                >
                  <a-input
                    v-model='custom_form.name'
                  />
                </a-form-model-item>
                <a-form-model-item
                  label='区域'
                >
                  <a-input
                    v-model='custom_form.name'
                  />
                </a-form-model-item>-->
      </a-form-model>
    </a-modal>

    <!-- 添加跟进人 -->
    <a-modal v-model="addFollowVisible" width="50%" title="添加跟进人" @ok="handleOkAddFollow" @cancel="closeAddFollow">
      <div class="add_input">
        <span>跟进人名称：</span>
        <a-input
            placeholder="请输入跟进人名称"
            v-model="emp_name"
            style="width: 300px;margin-right: 40px"
        />
        <a-button
            class="mr-10"
            type="primary"
            :title="'搜索'"
            @click="serechFollowName"
        >
          搜索
        </a-button>
      </div>
      <div>
        <a-table
            :columns="follow_columns"
            :data-source="followIdList"
            :pagination="false"
            :rowKey="(record, index) => index"
            :scroll="{ y: 400}"
        >
          <div slot="operation" slot-scope="text, record">
            <a-checkbox @change="(e) => handleChexkoutFollow(e,record)"></a-checkbox>
          </div>
        </a-table>
      </div>
      <!--      <div>
              <a-checkbox-group v-model="followList">
                <a-checkbox :value="item.id" v-for="item in followIdList" style="margin: 15px 18px">{{ item.employee_name }}}</a-checkbox>
              </a-checkbox-group>
            </div>-->
    </a-modal>
  </div>
</template>

<script>
import templateList from "./components/list";
import _https from "@/api/training_list";

const debounce = (function() {
  let timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default {
  components: {templateList},
  data() {
    return {
      keysError: "",
      codeError: "",
      keysSuccess: "",
      activity_name: this.$route.query.activity_name || "",
      dealer_count: 0,
      activity_id: this.$route.query.activity_id || "",
      principal_id: this.$route.query.principal_id || "",
      search_form: {},
      layout: {
        labelCol: {span: 6},
        wrapperCol: {span: 18}
      },
      columns: [
        {
          dataIndex: "dealer_name",
          scopedSlots: {customRender: "dealer_info"},
          width: 250,
          title: "经销商信息"
        },
        {
          dataIndex: "group_name",
          scopedSlots: {customRender: "custom_info"},
          width: 250,
          title: "自定义信息"
        },
        {
          dataIndex: "account",
          width: 300,
          title: "媒体帐号",
          scopedSlots: {customRender: "account"}
        },
        {
          dataIndex: "status",
          width: 360,
          title: "授权状态",
          scopedSlots: {customRender: "status"}
        },
        {
          dataIndex: "operation",
          title: "操作",
          width: 200,
          scopedSlots: {customRender: "operation"}
        }
      ],
      group_columns: [
        {
          dataIndex: "groupName",
          title: "名称"
        },
        {
          dataIndex: "mtime",
          title: "修改日期"
        },
        {
          dataIndex: "operation",
          title: "操作",
          scopedSlots: {customRender: "operation"}
        }
      ],
      upload_success_columns: [
        {
          dataIndex: "dealer_count",
          title: "经销商数量"
        },
        {
          dataIndex: "douyin_participate_count",
          title: "抖音帐号数"
        },
        {
          dataIndex: "kuaishou_participate_count",
          title: "快手帐号数"
        },
        {
          dataIndex: "dongchedi_participate_count",
          title: "懂车帝帐号数"
        }
      ],
      upload_error_columns: [
        {
          dataIndex: "dealer_name",
          title: "经销商信息",
          scopedSlots: {customRender: "dealerInfo"}
        },
        {
          dataIndex: "author_id",
          title: "媒体帐号信息",
          scopedSlots: {customRender: "activityInfo"}
        },
        {
          dataIndex: "validate_message",
          title: "异常信息",
          scopedSlots: {customRender: "errorMsg"}
        }
      ],
      data_list: [],
      upload_success_data_list: [],
      upload_error_data_list: [],
      groupNameList: [],
      loading: false,
      drawerVisible: false,
      addGroupVisible: false,
      file_status: null,
      total: 0,
      current: 1,
      page_size: 10,
      selectedRowKeys: [],
      RowKeys: [],
      file_list: [],
      custom_request_type: "excel",
      is_upload: false,
      group_id: undefined,
      groupId: undefined,
      group_list: [],
      is_submit: false,
      group_name: "",
      groupName: "",
      groupIdkey: "",
      addAccountVisible: false,
      search_account_form: {
        platform: "抖音",
        nickname: ""
      },
      accountName: "",
      account_list: [],
      account_columns: [
        {
          dataIndex: "avatar",
          scopedSlots: {customRender: "account"},
          title: "头像"
        },
        {
          dataIndex: "nickname",
          title: "昵称"
        },
        {
          dataIndex: "code",
          title: "平台号"
        },
        {
          dataIndex: "author_id",
          title: "主播ID",
          width: 140
        },
        {
          dataIndex: "dealer_name",
          scopedSlots: {customRender: "dealer_name"},
          title: "经销商"
        },
        {
          dataIndex: "operation",
          title: "操作",
          scopedSlots: {customRender: "operation"}
        }
      ],
      accountRecord: {},
      account_total: 0,
      account_current: 1,
      account_page_size: 10,
      creatorPlatformList: [
        {id: 0, name: "未授权"},
        {id: 1, name: "已授权"},
        {id: 2, name: "授权失效"}
      ],
      WeChatStatusList: [
        {id: 0, name: "未绑定"},
        {id: 1, name: "已绑定"}
      ],
      openPlatformList: [
        {id: 0, name: "未授权"},
        {id: 1, name: "已授权"},
        {id: 2, name: "授权过期"},
        {id: 3, name: "授权失败"},
        {id: 4, name: "授权审核中"}
        // {id: 5, name: '授权失败'},
        // {id: 6, name: '授权失败'},
      ],
      customVisible: false,
      custom_form: {
        name: ""
      },
      formItemLayout: {
        labelCol: {span: 4},
        wrapperCol: {span: 10}
      },
      header_list: ["经销商ID", "经销商名称", "经销商编号", "媒体帐号ID", "平台"],
      inputValue: "",
      inputVisible: false,
      inputValueArr: [],
      unbind_weixin_count: 0,
      author_count: this.$route.query.author_count || 0,
      p1_exception_count: this.$route.query.p1_exception_count || 0,
      p2_exception_count: this.$route.query.p2_exception_count || 0,
      remarksObj: {},
      Obj: {},
      row: {},
      follow_id_list: [],
      emp_name: "",
      isFollowLoading: false,
      addFollowVisible: false,
      follow_columns: [
        {
          dataIndex: "id",
          title: "ID"
        },
        {
          dataIndex: "employee_name",
          title: "跟进人名称"
        },
        {
          dataIndex: "operation",
          title: "选择",
          scopedSlots: {customRender: "operation"}
        }
      ],
      followIdList: [],
      followList: [],
      open_type: 1,
      templateList: [],
    };
  },
  computed: {
    rowSelection() {
      const {selectedRowKeys} = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys;
        }
      };
    }
  },
  mounted() {
    this.getGroupList();
    this.getList();
    this.getFollowIdList();
    // this.getAccountList()
  },
  methods: {
    handleSave() {
      // if (!this.group_name) {
      //   this.$message.error('分组名称不能为空')
      //   return false
      // }
      if(!this.keysSuccess) {
        this.$message.error("key不能为空");
        return false;
      }
      let params = {
        activity_id: this.activity_id,
        key: this.keysSuccess,
        creator: this.$router.app.user.realname,
        group_name: this.group_name,
      };
      this.is_upload = true;
      _https.saveExcel(params).then(res => {
        if(res.code == 200) {
          this.$message.success(res.message || "保存成功");
          this.drawerVisible = false;
          this.getList();
        } else {
          this.$message.error(res.message || "保存失败");
        }
        this.is_upload = false;
      });
    }, // 保存
    async exportExcelModel() {
      try {
        const extra_title = this.inputValueArr.length ? this.inputValueArr.join(",") : "";
        const res = await _https.exportExcelModel(extra_title);
        const fileName = "经销商媒体帐号导入模板";

        /* 兼容ie内核，360浏览器的兼容模式 */
        if(window.navigator && window.navigator.msSaveOrOpenBlob) {
          const blob = new Blob([res]);
          window.navigator.msSaveOrOpenBlob(blob, fileName + ".xlsx");
        } else {
          /* 火狐谷歌的文件下载方式 */
          let blob = new Blob([res]);
          let downloadElement = document.createElement("a");
          let href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = fileName + ".xlsx";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      } catch(e) {
        console.log(e);
      }
    },//下载经销商模板
    downloadDealerError() {
      let params = {
        key: this.keysError,
        code: this.codeError
      };
      let fileName = "经销商异常数据" + new Date().getTime();
      _https.downloadDealerError(params).then(res => {
        /* 兼容ie内核，360浏览器的兼容模式 */
        if(window.navigator && window.navigator.msSaveOrOpenBlob) {
          const blob = new Blob([res]);
          window.navigator.msSaveOrOpenBlob(blob, fileName + ".xlsx");
        } else {
          /* 火狐谷歌的文件下载方式 */
          let blob = new Blob([res]);
          let downloadElement = document.createElement("a");
          let href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = fileName + ".xlsx";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
        }
      });
    },//下载异常数据
    handleChexkout(record) {
      this.groupName = record.groupName;
      this.groupIdkey = record.id;
      // }
    },// 选择分组
    handleDelete(id) {
      const that = this;
      that.$confirm({
        content: "确定要删除该分组吗？",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _https.deteleGroup({id}).then(res => {
            if(res.code == 200) {
              that.$message.success("删除分组成功");
              that.getGroupList();
            } else {
              that.$message.error("删除分组失败");
            }
          });
        }
      });
    },// 删除分组
    addGroupName() {
      if(!this.group_name) {
        this.$message.error("分组名称不能为空");
        return false;
      }
      const list = [
        {
          "activity_id": this.activity_id,
          "creator": this.$router.app.user.realname,
          "group_name": this.group_name,
          "sort": 0
        }
      ];
      _https.addActivitesGroup({list}).then(res => {
        if(res.code == 200) {
          this.group_name = "";
          this.getGroupList();
        } else {
          this.$message.error("添加分组失败");
        }
      });
    },// 添加分组名称
    onChangeRowSelection(selectedRowKeys, selectedRows) {
      this.RowKeys = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
    },
    handleOkAddGroup() {
      let ids = this.RowKeys.map((i) => {
        return i.dealer_id;
      });
      ids = ids.filter(function(item, index, ids) {
        return ids.indexOf(item, 0) === index;
      }); // 去重
      let params = {
        dealer_ids: ids,
        group_id: this.groupIdkey,
        activities_id: this.activity_id,
        creator: this.$router.app.user.realname,
        modifier: this.$router.app.user.realname
      };
      _https.bindDealerGroup(params).then(res => {
        if(res.code == 200) {
          this.addGroupVisible = false;
          this.$message.success(res.data);
          this.getList();
        } else {
          this.$message.error(res.data || "添加分组失败");
        }
      });
    },// 添加分组
    handleCancel() {
      this.drawerVisible = false;
    },
    showDrawer() {
      this.drawerVisible = true;
      this.group_name = this.activity_name;
      this.inputValueArr = [];
      this.header_list = ["经销商ID", "经销商名称", "经销商编号", "媒体帐号ID", "平台"],
          this.inputValue = "";
      this.file_status = null;
      this.keysSuccess = "";
      this.keysError = "";
      this.upload_success_data_list = [];
      this.upload_error_data_list = [];
      this.file_list = [];
    },
    //搜索
    bindSearch() {
      this.current = 1;
      this.getList();
    },
    //重置
    resetSearch() {
      this.search_form = {};
      this.current = 1;
      this.page_size = 10;
      this.getList();
    },
    //翻页
    onChange(current, page_size) {
      this.current = current;
      this.page_size = page_size;
      this.getList();
    },
    //检索列表
    getList() {
      this.loading = true;
      let params = {
        dealer_name: this.search_form.dealer_name,
        dealer_id: this.search_form.dealer_id,
        activity_id: this.activity_id,
        group_id: this.search_form.groupId,
        weixin_status: this.search_form.weixin_status,
        follow_id: this.search_form.follow_id,
        p1_oauth_status: this.search_form.p1_oauth_status,
        p2_oauth_status: this.search_form.p2_oauth_status,
        code: this.search_form.code,
        author_id: this.search_form.author_id,
        current: this.current,
        page_size: this.page_size
      };
      _https.getDealerList(params).then(res => {
        this.loading = false;
        this.selectedRowKeys = [];
        if(res.code == 200) {
          let arr = res.data.list || [];
          console.log(arr);
          if(arr.length) {
            arr.forEach(item => {
              item.remarksObj = JSON.parse(item.remarks);
              item.follows = item.follow ? JSON.parse(item.follow) : {};
              item.follow_name = Object.values(item.follows).join(",") || "";
            });
          }
          this.data_list = arr;
          this.total = res.data.pagination.total;
          this.dealer_count = res.data.dealer_count || 0;
          this.unbind_weixin_count = res.data.unbind_weixin_count || 0;
        } else {
          this.data_list = [];
          this.total = 0;
          this.$message.error("获取经销商列表失败");
        }
      });
    },
    async customRequest({file, onSuccess}) {
      // this.is_upload = true
      const formData = new FormData();
      formData.append("activity_id", this.activity_id);
      formData.append("creator", this.$router.app.user.realname);
      formData.append("file", file);

      _https.addDealer(formData).then(res => {
        if(res.code == 200) {
          this.file_status = 1;
          // this.is_upload = false
          this.upload_success_data_list = [
            {
              dealer_count: res.data.dealer_count || 0,
              dongchedi_participate_count: res.data.dongchedi_participate_count || 0,
              douyin_participate_count: res.data.douyin_participate_count || 0,
              kuaishou_participate_count: res.data.kuaishou_participate_count || 0
            }
          ];
          this.keysSuccess = res.message;
        } else {
          this.file_status = 0;
          // this.is_upload = true
          this.keysError = res.message;
          this.codeError = res.code;
          if(res.code == 116201) {
            this.$message.error(res.data || "经销商帐号超出数量限制");
          } else if(res.code == 116202) {
            this.upload_error_data_list = res.data || [];
            this.$message.error("经销商帐号校验异常");
          }
        }
      });
    }, // 校验经销商文件
    beforeUpload(file) {
      const arr = file.name.split(".");
      const type = arr[arr.length - 1].toUpperCase();
      const isExcel = ["XLSX", "XLS"].includes(type);

      if(!isExcel) {
        this.$message.error("请上传Excel文件");
      }

      return isExcel;
    },
    handleUploadChange(info) {
      let fileList = [...info.fileList];

      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if(file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      this.file_list = fileList;
      //
      // this.file_list = info.fileList
      if(info.file.status === "error") {
        this.$message.error(`${ info.file.name } 文件上传失败`);
      }
      if(info.file.status === "uploading") {
        info.file.status = "success";
        // this.$message.error(`${info.file.name} 文件上传失败`)
      }
    }, //上传文件
    getGroupList() {
      _https.getGroupList({activity_id: this.activity_id}).then(res => {
        if(res.code == 200) {
          this.group_list = res.data;
        } else {
          this.$message.error("获取分组失败");
        }
      });
    },
    addGroup() {
      if(this.selectedRowKeys.length < 1) {
        this.$warning({
          title: `请先勾选经销商后再添加分组`
        });
        return;
      }
      this.addFollowVisible = true;
      this.serechFollowName();
      // this.addGroupVisible = true
      // this.group_name = ''
      // this.groupName = ''
    },
    clickRowKey(type, record) {
      switch(type) {
        case 1:
          let params = {
            id: record.id,
            dealer_id: record.dealer_id,
            activity_id: this.activity_id,
            modifier: this.$router.app.user.realname
          };
          let that = this;
          this.$confirm({
            title: "你确定要删除这行内容吗？",
            okText: "确定",
            cancelText: "取消",
            onOk() {
              _https.deleteRowKey(params).then(res => {
                if(res.code == 200) {
                  that.$message.success("删除成功");
                  that.getList();
                } else {
                  that.$message.error("删除失败");
                }
              });
            }
          });
          break;
        case 2:
          this.customVisible = true;
          this.remarksObj = record.remarksObj;
          this.Obj = _.cloneDeep(this.remarksObj);
          this.row = record;
          break;
        default:
          console.log("触发");
      }
    }, // 删除
    searchAccount() {
      this.getAccountList();
    }, //媒体帐号列表搜索
    //翻页
    onChangeAccount(current, page_size) {
      this.account_current = current;
      this.account_page_size = page_size;
      this.getAccountList();
    },
    handleOkSaveAccount() {
      if(!this.accountName) {
        this.$message.error("请先选择需要添加的媒体帐号");
        return false;
      }
      let params = [{
        dealer_id: this.accountRecord.dealer_id,
        activity_id: this.activity_id,
        author_id: this.accountRecord.author_id,
        platform: this.accountRecord.platform
      }];
      _https.saveAccount(params).then(res => {
        if(res.code == 200) {
          this.$message.success("添加成功");
          this.addAccountVisible = false;
          this.account_list = [];
          this.accountName = "";
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    }, // 添加媒体帐号
    handleAccountChexkout(record) {
      this.accountRecord = record;
      let params = {
        dealer_id: record.dealer_id,
        activity_id: this.activity_id,
        author_id: record.author_id,
        platform: record.platform
      };
      _https.checkAuthor(params).then(res => {
        if(res.code == 200) {
          this.accountName = record.nickname;
        } else {
          this.$message.error(res.message);
        }
      });
    }, // 选择 校验媒体帐号合法性
    showaddAccountVisible() {
      this.addAccountVisible = true;
      this.search_account_form = {
        platform: "抖音",
        nickname: ""
      },
          this.account_current = 1;
      this.account_page_size = 10;
      this.getAccountList();
    },
    getAccountList() {
      let params = {
        platform: this.search_account_form.platform,
        nickname: this.search_account_form.nickname,
        current: this.account_current,
        page_size: this.account_page_size
      };
      _https.getAccountList(params).then(res => {
        if(res.code == 200) {
          this.account_list = res.data.list;
          this.account_total = res.data.pagination.total;
        } else {
          this.$message.error("获取媒体帐号列表失败");
        }
      });
    }, // 媒体帐号列表
    handleClose(removedTag) {
      const tags = this.header_list.filter(tag => tag !== removedTag);
      const tags1 = this.inputValueArr.filter(tag => tag !== removedTag);
      this.header_list = tags;
      this.inputValueArr = tags1;
    }, // 删除表头
    showInput() {
      this.inputVisible = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    }, // 显示输入框
    handleOkCustomVisible() {
      let params = {
        remarks: this.Obj || {},
        id: this.row.id || null,
        modifier: this.$router.app.user.realname || ""
      };
      _https.putRemarksObj(params).then(res => {
        if(res.code == 200) {
          this.getList();
          this.$message.success("自定义信息修改成功");
        } else {
          this.$message.error("自定义信息修改失败");
        }
        this.customVisible = false;
      });
    }, //自定义信息
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
    handleInputConfirm() {
      const inputValue = this.inputValue;
      let header_list = this.header_list;
      if(inputValue && header_list.indexOf(inputValue) === -1) {
        header_list = [...header_list, inputValue];
        this.inputValueArr.push(inputValue);
        Object.assign(this, {
          header_list,
          inputVisible: false,
          inputValue: ""
        });
      } else {
        this.$message.error("请输入表头名称");
      }
    },
    handleInputClose() {
      this.inputVisible = false;
      this.inputValue = "";
    },
    pageJump() {
      this.$router.push({
        name: "actManage:kpiList",
        query: {
          activity_name: this.activity_name,
          activity_id: this.activity_id,
          principal_id: this.principal_id
        }
      });
    },
    //跟进人数据
    getFollowIdList() {
      _https.getFollowIdList(this.emp_name).then(res => {
        if(res.code == 200) {
          this.follow_id_list = res.data || [];
        } else {
          this.follow_id_list = [];
          this.$message.error(res.message || "获取跟进人数据失败");
        }
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    handleDealerSearch(value) {
      console.log("handleDealerSearch", value);
      debounce(() => {
        if(value) {
          this.isFollowLoading = true;
          this.emp_name = value;
          this.getFollowIdList();
        } else {
          this.isFollowLoading = false;
          this.follow_id_list = [];
        }
      }, 500);
    },
    handleOkAddFollow() {
      console.log(this.followList.length);
      if(!this.followList.length) {
        this.$message.error("请选择跟进人");
        return false;
      }
      let follow = {};
      this.followList.forEach(item => {
        follow[item.id] = item.employee_name;
      });
      const list = this.selectedRowKeys.map(id => ({id, follow}));
      const params = {
        param: list
      };
      _https.AddFollow(params).then(res => {
        if(res.code == 200) {
          if(res.data.fail_id.length) {
            let err_msg = res.data.fail_id.map(item => item).join(",") + res.data.fail_msg || "找不到此id对应的信息";
            this.$message.error(err_msg);
          } else {
            this.$message.success(res.message || "添加跟进成功");
          }
        } else {
          this.$message.error(res.message || "添加跟进失败");
        }
        this.addFollowVisible = false;
        this.followIdList = [];
        this.followList = [];
        this.selectedRowKeys = [];
        this.getList();
      });
    },
    closeAddFollow() {
      this.addFollowVisible = false;
      this.followIdList = [];
      this.followList = [];
      this.emp_name = "";
    },
    serechFollowName() {
      this.followIdList = [];
      this.followList = [];
      _https.getFollowIdList(this.emp_name).then(res => {
        if(res.code == 200) {
          this.followIdList = res.data || [];
        } else {
          this.followIdList = [];
          this.$message.error(res.message || "获取跟进人数据失败");
        }
      });
    },
    handleChexkoutFollow(e, row) {
      if(e.target.checked) {
        this.followList = this.followList.concat(row);
      } else {
        this.followList = this.followList.filter((item) => item.id !== row.id);
      }
      console.log(this.followList, "a-checkbox过滤数据");
    },
    changeRadio() {
      this.group_name = this.activity_name;
      this.inputValue = "";
      this.file_list = [];
      this.header_list = ["经销商ID", "经销商名称", "经销商编号", "媒体帐号ID", "平台"];
      this.upload_success_data_list = [];
      this.upload_error_data_list = [];
      this.inputVisible = false;
      this.file_status = null;
      // this.is_upload = false
    },
    handleCancelTemplate() {
      this.drawerVisible = false;
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
.main-title {
  font-size: 25px;

  span {
    padding-left: 10px;
    font-size: 14px;
    color: #f5a60b;
  }
}

.search-form .ant-form-item {
  margin-bottom: 10px;
}

.mt-40p {
  margin-top: 40px;
}

.mr-10 {
  margin-right: 10px;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.operation-box {
  display: flex;
  align-items: center;
  margin: 20px 15px;

  & > div {
    margin-right: 10px;
  }

  .num {
    font-size: 14px;
    color: #f5a60b;
    padding-right: 30px;
  }

  .red {
    color: red;
  }
}

.danger_btn {
  position: absolute;
  top: -44px;
  right: 26px;
}

.upload_box {
  width: 65%;
  height: 210px;
  border: 2px dashed #00A0E9;
  border-radius: 10px;
}

.upload_box /deep/ .ant-upload.ant-upload-drag {
  border: none !important;
}

.text_icon_box {
  padding: 30px 30px 10px 40px;
  text-align: center;

  .text_p {
    padding-top: 30px;
  }
}

.file_name_box {
  font-size: 14px;
  margin-left: 20px;
}

.a-upload {
}

.model_btn {
  position: absolute;
  bottom: 20px;
  right: 40px;

  a {
    padding-right: 20px;
  }
}

.account_box {
  display: flex;
  //justify-content: center;
  align-items: center;
  padding: 3px 0;

  .img_box {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ccc;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .account_right {
    .name {
      font-size: 14px;
    }

    .account {
      display: flex;
      align-items: center;

      p {
        font-weight: bold;
        margin-left: 10px;
        margin-bottom: 0px;
      }
    }
  }

}

.add_input {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.group_name {
  padding-top: 10px;
  font-weight: bold;

  span {
    font-weight: 400;
    color: #00A0E9;
    padding: 0 6px;
  }
}

.del {
  color: red;
  margin-left: 20px;
}

.success_box {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #ccc;

  .icon—box {
    display: flex;
    align-items: center;

    div {
      padding-left: 20px;

      p {
        font-size: 26px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 0px;
      }

      span {
        font-size: 14px;
        color: #9d9c9c;
      }
    }
  }

  .success_tabs {
    margin-top: 20px;
  }
}

.avatar_box {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.dealer_info {
  padding: 3px 0;

  &:nth-child(1) {
    padding-top: 0px;
  }

  &:last-child {
    padding-bottom: 0px;
  }
}

.header_list {
  padding-top: 20px;
}

.header_list /deep/ .ant-tag {
  margin-right: 0px;
  padding: 5px 18px;
  font-size: 14px;
  background: transparent;
  //margin-bottom: 4px;
}

.header_list /deep/ .ant-tag:nth-last-child(2) {
  margin-right: 30px;
}

.header_list /deep/ .ant-input-sm {
  height: 32px;
}

.header_list_title {
  font-size: 18px;
  font-weight: bold;
  padding-top: 20px;
}

.row-box {
  display: flex;
  margin-bottom: 5px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }

  & > div:last-child {
    flex: 1;
  }
}

.author-box {
  & > div:first-child {
    width: 70px;
  }
}

.i_icon {
  display: inline-block;
  font-size: 24px;
  color: red;
}
</style>
