<template>
  <div>
    <a-form-model
      v-bind="layout"
      ref="search_form"
      :model="search_form"
    >
      <a-row>
        <a-col :span="8">
          <a-form-model-item label="分组名称">
            <a-input
              placeholder="请输入分组名称"
              v-model="search_form.group_name"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <!-- <a-form-model-item label="所属主体">
            <a-select
              v-model="search_form.principal_id"
              show-search
              placeholder="请选择主体"
              option-filter-prop="children"
              :allow-clear="true"
            >
              <a-select-option key=""> 全部</a-select-option>
              <a-select-option v-for="d in principalList" :key="d.id" :value="d.id">
                {{ d.principal_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item> -->
        </a-col>
        <a-col :span="8" class="flex-end">
          <a-button
            class="mr-10"
            type="primary"
            icon="search"
            @click="bindSearch"
          >
            搜索
          </a-button>
          <a-button
            class="mr-10"
            icon="redo"
            @click="resetSearch"
          >
            重置
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="title">
      分组列表
    </div>
    <a-table
      :columns="templateColumns"
      :data-source="list"
      :loading="loading"
      :pagination="false"
      :rowKey="record => record.id"
      class="mb-20"
      bordered
      :scroll="{ y: 300 }"
      :row-selection="rowSelection"
    >
      <div slot="status">
        <span>无告警</span>
      </div>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
      :currentPage="pagination.page_num "
      :options="['10', '20', '50']"
      :pageSize="pagination.page_size"
      :total="pagination.total"
      class="base-pagination"
      @onChange="onChange"
      @onShowSizeChange="onChange"
    >
    </base-pagination>
    <div style="padding-bottom: 20%">
      <div class="title">
        明细列表
      </div>
      <a-tabs v-model="activeKey">
        <a-tab-pane key="1" tab="已选择">
          <a-table
            :columns="detailColumns"
            :data-source="detail_list"
            :loading="detail_loading"
            :pagination="false"
            :rowKey="record => record.id"
            class="mb-20"
            bordered
            :scroll="{ y: 300 }"
            :row-selection="detail_rowSelection"
          >
            <div slot="dealerNumber" slot-scope="text, record">
              {{ record.dealer_number || '-' }}
            </div>
            <div slot="platformType" slot-scope="text, record">
              <span v-if="record.platform">
                {{ record.platform }}
              </span>
              <span v-else>
                {{ record.platform_type | platformTypeFilter }}
              </span>
            </div>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="待选择">
          <a-table
            :columns="detailColumns"
            :data-source="detail_list_no"
            :loading="detail_loading"
            :pagination="false"
            :rowKey="record => record.id"
            class="mb-20"
            bordered
            :scroll="{ y: 300 }"
            :row-selection="detail_rowSelection_no"
          >
            <div slot="dealerNumber" slot-scope="text, record">
              {{ record.dealer_number || '-' }}
            </div>
            <div slot="platformType" slot-scope="text, record">
              <span v-if="record.platform">
                {{ record.platform }}
              </span>
              <span v-else>
                {{ record.platform_type | platformTypeFilter }}
              </span>
            </div>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>

    <div class="flex-end model_btn">
      <a-button
        type="primary"
        :loading="is_upload"
        :disabled="is_upload"
        @click="handleSave"
      >
        保存
      </a-button>
    </div>
  </div>
</template>

<script>
import _api from '@/api/training_list'

export default {
  name: 'list',
  data () {
    return {
      activeKey: '1',
      selectedRows: [],
      selectedRowKeys: [],
      search_form: {},
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      },
      principalList: [],
      list: [],
      templateColumns: [
        {
          dataIndex: 'name',
          title: '分组名称'
        },
      ],
      detailColumns: [
        {
          dataIndex: 'dealer_id',
          title: '经销商ID'
        },
        {
          dataIndex: 'dealer_name',
          title: '经销商名称'
        },
        {
          dataIndex: 'dealer_number',
          title: '经销商编号',
          scopedSlots: { customRender: 'dealerNumber' },
        },
        {
          dataIndex: 'author_id',
          title: '媒体帐号ID'
        },
        {
          dataIndex: 'platform_type',
          scopedSlots: { customRender: 'platformType' },
          title: '平台'
        },
      ],
      detail_list: [],
      loading: false,
      pagination: {
        total: 0,
        page_size: 10,
        page_num: 1
      },
      detail_loading: false,
      detail_pagination: {
        total: 0,
        page_size: 10,
        page_num: 1
      },
      detail_selectedRows: [],
      detail_selectedRowKeys: [],
      detail_selectedRows_no: [],
      detail_selectedRowKeys_no: [],
      groupId: undefined,
      detail_list_no: [],
      time_key: +new Date(),
      is_upload: false,
    }
  },
  props: {
    activityId: { type: String },
    principalId: { type: [String, Number] }
  },
  computed: {
    rowSelection () {
      const { selectedRowKeys, selectedRows } = this
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows
          this.selectedRowKeys = selectedRowKeys
          this.groupId = selectedRowKeys
          this.getDetailList()
        },
      }
    },
    detail_rowSelection () {
      const { detail_selectedRowKeys, detail_selectedRows } = this
      let that = this
      return {
        detail_selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          that.detail_selectedRowKeys = selectedRowKeys
        },
        onSelectAll (selected, selectedRows, changeRows) {
          that.detail_list_no = [...that.detail_list_no, ...changeRows]
          let arr = changeRows.map(item => item.id)
          // 过滤
          const list = that.detail_list.filter(tag => !arr.includes(tag.id))
          const newArr = [].concat.apply([], list)
          that.detail_list = newArr
          // that.$nextTick(() => {
          //   setTimeout(() => {
          //     this.time_key = +new Date()
          //   }, 1000)
          // })
        },
        onSelect (selectedRows, selected) {
          let newList = []
          newList.push(selectedRows)
          that.detail_list_no = [...that.detail_list_no, ...newList]
          let arr = newList.map(item => item.id)
          console.log(arr)
          // 过滤
          const list = that.detail_list.filter(tag => !arr.includes(tag.id))
          const newArr = [].concat.apply([], list)
          that.detail_list = newArr
          // that.$nextTick(() => {
          //   setTimeout(() => {
          //     this.time_key = +new Date()
          //   }, 1000)
          // })
        },
        selectedRowKeys: this.detail_selectedRowKeys
      }
    },
    detail_rowSelection_no () {
      const { detail_selectedRowKeys_no, detail_selectedRows_no } = this
      return {
        detail_selectedRowKeys_no,
        onChange: (selectedRowKeys, selectedRows) => {
          this.detail_selectedRowKeys_no = selectedRowKeys
          if (selectedRowKeys.length) {
            this.detail_list = [...this.detail_list, ...selectedRows]
            // 过滤
            const list = this.detail_list_no.filter(tag => !selectedRowKeys.includes(tag.id))
            const newArr = [].concat.apply([], list)
            this.detail_list_no = newArr
            this.detail_selectedRowKeys = this.detail_list.map(item => item.id)
            this.detail_selectedRows = this.detail_list
            // this.$nextTick(() => {
            //   setTimeout(() => {
            //     this.time_key = +new Date()
            //   }, 1000)
            // })
          }
        },
      }
    },
  },
  mounted () {
    this.getList()
  },
  methods: {
    //搜索
    bindSearch () {
      this.pagination.page_num = 1
      this.getList()
    },
    //重置
    resetSearch () {
      this.search_form = {}
      this.pagination.page_num = 1
      this.pagination.page_size = 10
      this.getList()
    },
    getList () {
      this.loading = true
      let params = {
        name: this.search_form.group_name,
        // principal_id: this.principalId,
        type: 1,
        page_num: this.pagination.page_num,
        page_size: this.pagination.page_size
      }
      _api.getGroupNameList(params).then(res => {
        this.loading = false
        this.selectedRowKeys = []
        this.selectedRows = []
        if (res.code == 200) {
          this.list = res.data.list || []
          this.pagination.total = res.data.total || 0
        } else {
          this.list = []
          this.pagination.total = 0
          this.$message.error('获取分组列表失败')
        }
      })
    },//分组列表
    getDetailList () {
      this.detail_loading = true
      this.detail_selectedRowKeys = []
      this.detail_selectedRows = []
      let params = {
        activity_id: this.activityId,
        group_ids: this.groupId || []
      }
      _api.getDetailList(params).then(res => {
        this.detail_loading = false
        if (res.code == 200) {
          this.detail_list = res.data.activity_data || []
          this.detail_selectedRows = res.data.activity_data || []
          const arr = Object.values(res.data.group_data || {})
          const newArr = [].concat.apply([], arr)
          // const newList = this.detail_list.filter(tag => !newArr.includes(tag.author_id))
          // console.log(newList)
          // return
          this.detail_list_no = newArr || []
          this.detail_selectedRowKeys = this.detail_list.map(item => item.id)
        } else {
          this.detail_list = []
          this.detail_selectedRows = []
          this.detail_selectedRowKeys = []
          this.$message.error('获取明细列表失败')
        }
      })
    },// 明细列表
    onChange (current, page_size) {
      this.pagination.page_num = current
      this.pagination.page_size = page_size
      this.getList()
    },
    detailOnChange (current, page_size) {
      this.detail_pagination.page_num = current
      this.detail_pagination.page_size = page_size
      this.getDetailList()
    },
    handleSave () {
      console.log(this.detail_selectedRows)
      if (!this.detail_selectedRows.length) {
        this.$message.error('请选择经销商明细')
        return false
      }
      this.is_upload = true
      this.detail_selectedRows.map(item => {
        // if(item.platform){
        //   item.platform_type = item.platform == '抖音' ? 1 :  item.platform == '快手' ? 2 : 0
        //
        // }
        delete item.is_deleted
      })
      console.log(this.detail_selectedRows)
      let params = {
        activity_id: this.activityId,
        dealer_list: this.detail_selectedRows
      }
      _api.saveDealer(params).then(res => {
        if (res.code == 200) {
          this.$message.success('保存成功')
        } else {
          this.$message.error('保存失败')
        }
        this.$emit('handleCancel')
        this.is_upload = false
        this.detail_list_no = []
        this.detail_list = []
        this.detail_selectedRows = []
        this.detail_selectedRowKeys = []
        this.detail_selectedRows_no = []
        this.detail_selectedRowKeys_no = []
        this.selectedRows = []
        this.selectedRowKeys = []
        this.groupId = []
      })
    }, // 保存
  },
  filters: {
    platformTypeFilter (val) {
      let text = '-'

      val === 1 && (text = '抖音')
      val === 2 && (text = '快手')
      val === 3 && (text = '懂车帝')
      
      return text
    }
  }
}
</script>

<style scoped lang="less">
.title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 15px 0;
}

.mr-10 {
  margin-right: 10px;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>